<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav">
      <li class="nav-item d-xl-none">
        <b-link
          v-if="userData.type !== 0"
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
      <dark-Toggler
        class="d-lg-block"
        style="margin: auto"
      />
      <li
        v-if="userData.type === 0"
        class="nav-item mx-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          :to="{ name:'Admin-panel' }"
        >
          <feather-icon
            icon="HomeIcon"
            class="mr-50"
          />
          <span class="align-middle"> Panel </span>
        </b-button>
      </li>
    </ul>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.names || "Usuario" }}
            </p>
            <span class="user-status">{{ userData.role || "Rol" }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          v-if="userData.canEdit"
          link-class="d-flex align-items-center"
          @click="setPanel"
        >
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Menú Inicio</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="userData.editEnabled !== true && (userData.registerComplete || userData.type === 1)"
          link-class="d-flex align-items-center"
          @click="editProfile"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Editar perfil</span>
        </b-dropdown-item>
        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Salir</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BButton,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,

    // Navbar Components
    DarkToggler,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      vuex: JSON.parse(localStorage.getItem('vuex')),
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('clientType')
      delete localStorage.clientType
      localStorage.removeItem('type')
      delete localStorage.type
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      // Remove userData from localStorage
      localStorage.removeItem('userData')
      delete localStorage.userData
      const vuexData = JSON.parse(localStorage.getItem('vuex'))
      vuexData.elementVerticalMenu.formSteps = []
      localStorage.clear()
      localStorage.setItem('vuex', JSON.stringify(vuexData))
      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    async setPanel() {
      if (this.userData.registerComplete === false) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Advertencia',
            icon: 'CoffeeIcon',
            variant: 'warning',
            text: 'Para volver al panel es necesario aceptar la Declaración jurada y Finalizar la edición',
          },
        },
        {
          timeout: 10000,
        })
      } else {
        this.userData.registerComplete = true
        localStorage.setItem('userData', JSON.stringify(this.userData))
        this.vuex.elementVerticalMenu = await store.dispatch('elementVerticalMenu/getData', null, { root: true })
        window.location.href = this.userData.type === 1 ? '/cliente/panel' : '/admin/panel'
      }
    },
    async editProfile() {
      if (this.userData.registerComplete === true) {
        useJwt.setEditProfile({
          email: JSON.parse(localStorage.getItem('userData')).email,
        }).then(async ({ data }) => {
          console.log('errores', data)
          this.userData.registerComplete = true
          this.userData.registerComplete = false
          localStorage.setItem('userData', JSON.stringify(this.userData))
          this.vuex.elementVerticalMenu = await store.dispatch('elementVerticalMenu/getData', null, { root: true })
          localStorage.setItem('vuex', JSON.stringify(this.vuex))
          if (this.userData.clientType === 0) {
            window.location.href = '/registro/datos-personales'
          }
          if (this.userData.clientType === 1) {
            window.location.href = '/registro/datos-cliente'
          }
        })
          .catch(error => {
            console.log('errores', error)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Advertencia',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: 'Error en la solicitud',
              },
            },
            {
              timeout: 8000,
            })
          })
      }
    },
  },
}
</script>
